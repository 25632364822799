<template>
	<div class="QuotesView">
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row">
				<div class="col-md-12">
					<h3 class="text-muted">Semana del {{ getDate(1, 'DD') }} al {{ getDate(6, 'DD') }} de {{ meses[moment(getDate(6, 'MM')).format('MM')] }} de {{ getDate(6, 'YYYY') }}</h3>
				</div>
			</div>
			<div class="row">
				<div class="col-md-4">
					<a-button class="btn btn-warning" icon="left" @click="weekCounter--" />
					<a-button class="btn btn-warning ml7" @click="weekCounter = 0">Semana actual</a-button>
					<a-button class="btn btn-warning ml7" icon="right" @click="weekCounter++" />
				</div>
				<div class="col-md-8 text-right">
					<a-button class="btn btn-info" icon="plus" @click="onShowModal('wizard')">Nueva cita</a-button>
					<a-button class="btn btn-success ml7" icon="reload" @click="initModule" />
				</div>
			</div>
			<div class="row pt10" style="padding-left: 15px">
				<div class="col-md-12">Acotación de colores para citas</div>
				<div class="col-md-3" style="border: 1px solid #000">Por confirmar</div>
				<div class="col-md-3 appointmentConfirmed">Confirmadas</div>
				<div class="col-md-3 appointmentIngresed">Ingresadas</div>
				<div class="col-md-3 appointmentCanceled">Canceladas</div>
			</div>
			<div class="row pt20">
				<div class="col-md-4 text-center" v-for="(day, index) in weekDays" :key="index">
					<div class="card bg-primary border-0 pointer" @click="filterAppointments(getDate(index + 1, 'YYYY-MM-DD'))">
						<div class="card-body">
							<div class="text-white font-weight-bold">
								<div class="font-size-21">{{ getAppointments(index + 1) }} <small>citas</small></div>
								<div class="font-size-18">
									{{ day }} <small>{{ getDate(index + 1, 'DD-MM-YYYY') }}</small>
								</div>
								<div class="row p0">
									<div class="col-md-3" style="background-color: #fff; color: #000">
										<a-tooltip>
											<template slot="title"> Por confirmar </template>
											{{ getStatusAppointments(index + 1, null) }}
										</a-tooltip>
									</div>
									<div class="col-md-3 appointmentConfirmed" style="color: #000">
										<a-tooltip>
											<template slot="title"> Confirmadas </template>
											{{ getStatusAppointments(index + 1, 'confirmed') }}
										</a-tooltip>
									</div>
									<div class="col-md-3 appointmentIngresed" style="color: #000">
										<a-tooltip>
											<template slot="title"> Ingresadas </template>
											{{ getStatusAppointments(index + 1, 'ingresed') }}
										</a-tooltip>
									</div>
									<div class="col-md-3 appointmentCanceled" style="color: #000">
										<a-tooltip>
											<template slot="title"> Canceladas </template>
											{{ getStatusAppointments(index + 1, 'canceled') }}
										</a-tooltip>
									</div>
									<div class="col-md-12 motor" style="color: #087990" v-if="getServices('motor', getDate(index + 1, 'YYYY-MM-DD'))">Reparación de motor</div>
								</div>
								<div class="font-size-10 text-left pt10">
									<div class="row">
										<div class="col-md-6">
											Mayor: {{ getServices('mayor', getDate(index + 1, 'YYYY-MM-DD')) }}
											<br />
											Menor: {{ getServices('menor', getDate(index + 1, 'YYYY-MM-DD')) }}
											<br />
											Diagnósticos: {{ getServices('diagnostico', getDate(index + 1, 'YYYY-MM-DD')) }}
											<br />
											Valoraciones: {{ getServices('valoracion', getDate(index + 1, 'YYYY-MM-DD')) }}
											<br />
										</div>
										<div class="col-md-6">
											Específicos: {{ getServices('especifico', getDate(index + 1, 'YYYY-MM-DD')) }}
											<br />
											Exploradoras: {{ getServices('exploradoras', getDate(index + 1, 'YYYY-MM-DD')) }}
											<br />
											GPS: {{ getServices('gps', getDate(index + 1, 'YYYY-MM-DD')) }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row" v-if="serviceOrdersListByDay.length">
				<div class="col-md-2 text-center"><span class="dot mayor"></span><br />Mayor</div>
				<div class="col-md-2 text-center"><span class="dot menor"></span> <br />Menor</div>
				<div class="col-md-2 text-center"><span class="dot diagnostico"></span> <br />Diagnósticos</div>
				<div class="col-md-2 text-center"><span class="dot valoracion"></span> <br />Valoraciones</div>
				<div class="col-md-2 text-center"><span class="dot especifico"></span> <br />Específicos</div>
				<div class="col-md-2 text-center"><span class="dot motor"></span> <br />Motor</div>
			</div>
			<div class="row pt10">
				<div class="col-md-12">
					<a-input-search v-model="searchText" ref="searchInput" placeholder="Ingresa parámetros de búsqueda" enter-button @search="onSearch" />
				</div>
			</div>
			<div class="row pt20" v-if="serviceOrdersListByDay.length">
				<div class="col-md-12">
					<a-table :columns="columns" :dataSource="serviceOrdersListByDay" rowKey="key" :pagination="false" :rowClassName="
						(_record, index) =>
							_record.confirmed && _record.ingresed ? 'appointmentIngresed' : _record.confirmed && !_record.ingresed ? 'appointmentConfirmed' : !_record.active ? 'appointmentCanceled' : ''
					">
						<div slot="client_data" slot-scope="record" class="text-left text-uppercase">
							{{ record.client_data.client_name }} {{ record.client_data.client_surname }}
							<br />
							{{ record.client_data.primary_phone | replaceIfLogicalFalse('') }}
						</div>
						<div slot="motorcycle_data" slot-scope="record" class="text-left">
							{{ record.motorcycle_data.nombre_marca | replaceIfLogicalFalse(record.motorcycle_data.marca_otro) }} /
							{{ record.motorcycle_data.nombre_modelo | replaceIfLogicalFalse(record.motorcycle_data.modelo_otro) }}
							<br />
							{{ record.motorcycle_data.anio }}
							<div>
								<span v-for="(service, index) in record.service_data.services" :key="index">
									<span class="dot" :class="service" v-if="isNaN(service)" />
								</span>
							</div>
							<div v-if="record.internal_comments">
								<hr class="hrDotted" />
								{{ record.internal_comments }}
							</div>
						</div>
						<div slot="quotation_amount" slot-scope="record">
							{{ numeral(record.quotation_amount).format('$0,0.00') | replaceIfLogicalFalse('-') }}
							<div v-if="record.quotation_id">
								<a-tag class="pointer" color="red" @click="downloadQuotation(record.quotation_id)">
									<a-icon type="file-pdf" />
								</a-tag>
							</div>
						</div>
						<div slot="appointment_date" slot-scope="record">
							{{ moment(record.appointment_date).format('DD-MM-YYYY') }}
							<br />
							{{ record.appreciationAppointmentDate }} hrs.
						</div>
						<div slot="appointment_reasons" slot-scope="record">
							<div>
								<a-button class="btn btn-primary" size="small" icon="eye" @click="onViewAppointment(record.id)" />
								<a-button class="ml7 btn btn-warning" size="small" icon="copy" @click="onCopyAppointment(record.id)" v-if="record.active" />
							</div>
							<div class="pt5" v-if="record.active">
								<a-button class="btn btn-info" size="small" icon="edit" @click="onEditAppointment(record.id)" v-if="!record.ingresed" />
								<a-button class="ml7 btn btn-success" size="small" icon="like" @click="onConfirmAppointment(record.id)" v-if="!record.confirmed" />
								<a-button class="ml7 btn btn-success" size="small" icon="check" @click="onIngresedAppointment(record.id)" v-if="record.confirmed && !record.ingresed" />
							</div>
							<div class="pt5" v-if="record.active && allowedCancelAppointment">
								<a-button class="btn btn-danger" size="small" icon="close" @click="onCancelAppointment(record.id)" v-if="!record.ingresed" />
							</div>
						</div>
					</a-table>
				</div>
			</div>
		</a-spin>
		<!-- Motivo de cita -->
		<a-modal width="50%" :visible="modal.appointmentReasons.visible" v-if="modal.appointmentReasons.visible" :footer="null" title="Detalle de la cita" :closable="true"
				 @cancel="onCloseModal('appointmentReasons')">
			<div>
				<div>
					<b>Cliente:</b>
					{{ appointmentRecord.client_data.client_name }} {{ appointmentRecord.client_data.client_surname }}
				</div>
				<div>
					<b>Motocicleta:</b>
					{{ appointmentRecord.motorcycle_data.nombre_marca | replaceIfLogicalFalse(appointmentRecord.motorcycle_data.marca_otro) }} /
					{{ appointmentRecord.motorcycle_data.nombre_modelo | replaceIfLogicalFalse(appointmentRecord.motorcycle_data.modelo_otro) }} {{ appointmentRecord.motorcycle_data.anio }}
				</div>
				<div>
					<b>Celular / Email:</b>
					{{ appointmentRecord.client_data.primary_phone | replaceIfLogicalFalse('') }}
					<br />
					{{ appointmentRecord.client_data.email | replaceIfLogicalFalse('') }}
				</div>
				<div>
					<b>Fecha de Cita:</b>
					{{ dias[moment(appointmentRecord.appointment_date).format('dddd').toLowerCase()] }}
					{{ moment(appointmentRecord.appointment_date).format('DD') }} de {{ meses[moment(appointmentRecord.appointment_date).format('MM')] }} de
					{{ moment(appointmentRecord.appointment_date).format('YYYY') }}
				</div>
				<div>
					<b>Motivo de la cita:</b>
					<div v-for="(service, index) in appointmentRecord.service_data.services" :key="index">
						<div v-if="service == 'especifico'">
							<div v-for="(s, i) in appointmentRecord.service_data.especificos" :key="i">- Servicio Específico: {{ s instanceof Object ? s.value : s }} || <b>{{
								numeral(s.amount).format('$0,0.00') }}</b></div>
						</div>
						<div v-if="service == 'diagnostico'">- Servicio Diagnóstico: {{ appointmentRecord.service_data.diagnostico }}</div>
						<div v-if="service == 'valoracion'">- Valoración Técnica || <b>{{ numeral(appointmentRecord.service_data.quotationAmounts.valoracion).format('$0,0.00') }}</b></div>
						<div v-if="service == 'mayor' || service == 'menor'">- Servicio {{ service }} || <b>{{ numeral(appointmentRecord.service_data.quotationAmounts.mayorMenor).format('$0,0.00') }}</b>
						</div>
						<div v-if="service == 'sliders'">- Instalación de sliders SHIELD</div>
						<div v-if="service == 'gps'">- Instalación de GPS || <b>{{ numeral(appointmentRecord.service_data.quotationAmounts.gps).format('$0,0.00') }}</b></div>
						<div v-if="service == 'exploradoras'">- Instalación de Exploradoras || <b>{{ numeral(appointmentRecord.service_data.quotationAmounts.exploradoras).format('$0,0.00') }}</b></div>
					</div>
				</div>
				<div v-if="appointmentRecord.internal_comments">
					<b>Comentarios Internos:</b>
					{{ appointmentRecord.internal_comments }}
				</div>
				<div>
					<b style="font-size: 30px;">Total: {{ numeral(appointmentRecord.quotation_amount).format('$0,0.00') }}</b>
				</div>
			</div>
		</a-modal>
		<!-- Registro de cliente -->
		<a-modal :visible="modal.addClient.visible" v-if="modal.addClient.visible" title="Añadir cliente" :closable="true" width="75%">
			<template slot="footer">
				<a-button key="back" @click="onCloseModal('addClient')"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="onCreateClient"> Añadir </a-button>
			</template>
			<clientsDetailGeneralComponent :showControls="false" :controlTaller="true" ref="clientsDetailGeneral" @selectedRecord="onSelectedClient" />
		</a-modal>
		<!-- Registro de motocicleta -->
		<a-modal :visible="modal.addMotorcycle.visible" v-if="modal.addMotorcycle.visible" title="Añadir motocicleta" :closable="true" width="50%">
			<template slot="footer">
				<a-button key="back" @click="onCloseModal('addMotorcycle')"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="onCreateMotorcycle"> Añadir </a-button>
			</template>
			<clientsDetailMotorcyclesComponent :visible="modal.addMotorcycle.visible" :showControls="false" :showList="false" :controlTaller="true" :clientData="payloadData.clientData"
											   ref="clientsDetailMotorcycles" @selectedRecord="onSelectedClient" @ok="onCloseModal('addMotorcycle')" />
		</a-modal>
		<!-- Registro de cita -->
		<a-modal :visible="modal.wizard.visible" title="Añadir cita" :closable="false" width="85%">
			<template slot="footer">
				<div class="row">
					<div class="col-md-6 text-left">
						<a-button key="back" @click="returnStep()" v-if="currentTab > 0"> Regresar </a-button>
					</div>
					<div class="col-md-6">
						<a-button class="btn btn-danger" key="back" @click="onCloseModal('wizard')"> Cancelar </a-button>
						<a-button key="submit" class="btn btn-success" @click="addClient"> Continuar </a-button>
					</div>
				</div>
			</template>
			<div>
				<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
					<a-steps :current="currentTab" size="small">
						<a-step title="Cliente" />
						<a-step title="Motocicleta" />
						<a-step title="Servicio" />
					</a-steps>
					<div v-show="currentTab == 0">
						<div class="pt20">
							<clientsQuickSearchComponent ref="clientsQuickSearchComponent" :visible="modal.wizard.visible" :showAddButton="false" :showAddButtonAfterNullSearch="true"
														 :searchType="'taller'" @selectedRecord="onSelectedClient" @addClient="onAddClient" />
						</div>
					</div>
					<div v-show="currentTab == 1">
						<div class="pt20">
							<clientsQuickSearchMotorcyclesComponent ref="clientsQuickSearchMotorcyclesComponent" @selectedRecord="onSelectedClient" @addMotorcycle="onAddMotorcycle" />
						</div>
					</div>
					<div v-show="currentTab == 2">
						<div class="row pt20">
							<div class="col-md-6">
								<label><b>== Servicio ==</b> <a-icon type="reload" @click="onResetService()" :style="{ fontSize: '12px', color: 'red' }" /></label>
								<div>
									<a-radio-group v-model="radioValue" @change="onChangeService">
										<a-radio :style="radioStyle" value="mayor"> Servicio mayor </a-radio>
										<a-radio :style="radioStyle" value="menor"> Servicio menor </a-radio>
										<a-radio :style="radioStyle" value="motor"> Reparación de Motor </a-radio>
									</a-radio-group>
									<a-input autocomplete="off" prefix="$" v-money="money" v-model="quotationAmounts.mayorMenor"
											 v-if="isServiceVisible('mayor') || isServiceVisible('menor') || isServiceVisible('motor')" />
								</div>
								<div :class="isServiceVisible('mayor') || isServiceVisible('menor') || isServiceVisible('motor') ? 'mt10' : ''">
									<div class="row">
										<div class="col-md-4">
											<a-checkbox @change="onChangeService" value="valoracion" :checked="isServiceVisible('valoracion')"> Valoración Técnica </a-checkbox>
											<div v-if="isServiceVisible('valoracion')">
												<a-input autocomplete="off" prefix="$" v-money="money" v-model="quotationAmounts.valoracion" disabled />
											</div>
										</div>
										<div class="col-md-4" :class="isServiceVisible('valoracion') ? 'pt10' : ''">
											<a-checkbox @change="onChangeService" value="diagnostico" :checked="isServiceVisible('diagnostico')"> Diagnóstico / Revisión </a-checkbox>
											<a-input autocomplete="off" prefix="$" v-money="money" v-model="quotationAmounts.diagnostico" v-if="isServiceVisible('diagnostico')" />
										</div>
										<div class="col-md-4">
											<a-checkbox @change="onChangeService" value="sliders" :checked="isServiceVisible('sliders')"> Sliders Shield </a-checkbox>
										</div>
										<div class="col-md-4">
											<a-checkbox @change="onChangeService" value="especifico" :checked="isServiceVisible('especifico')"> Servicio Específico </a-checkbox>
										</div>
										<div class="col-md-4">
											<a-checkbox @change="onChangeService" value="exploradoras" :checked="isServiceVisible('exploradoras')"> Exploradoras </a-checkbox>
											<a-input autocomplete="off" prefix="$" v-money="money" v-model="quotationAmounts.exploradoras" v-if="isServiceVisible('exploradoras')" />
										</div>
										<div class="col-md-4">
											<a-checkbox @change="onChangeService" value="gps" :checked="isServiceVisible('gps')"> GPS </a-checkbox>
											<a-input autocomplete="off" prefix="$" v-money="money" v-model="quotationAmounts.gps" v-if="isServiceVisible('gps')" />
										</div>
									</div>
								</div>
								<div>
									<hr class="hrDotted" />
								</div>
								<div class="row">
									<div class="col-md-6">
										<label><b>== Fecha de cita ==</b></label>
										<br />
										<a-date-picker placeholder="Selecciona fecha" valueFormat="YYYY-MM-DD" format="DD-MM-YYYY" v-model="appointmentDate.date" :disabled-date="disabledDate"
													   style="width: 100%" />
									</div>
									<div class="col-md-6">
										<label><b>== Hora de cita ==</b></label>
										<br />
										<a-select style="width: 50%" v-model="appointmentDate.hours">
											<a-select-option value="09" v-if="!isServiceVisible('valoracion')"> 09 </a-select-option>
											<a-select-option value="10" v-if="!isServiceVisible('valoracion')"> 10 </a-select-option>
											<a-select-option value="11"> 11 </a-select-option>
											<a-select-option value="12"> 12 </a-select-option>
											<a-select-option value="13" v-if="!isServiceVisible('valoracion')"> 1 </a-select-option>
											<a-select-option value="14" v-if="!isServiceVisible('valoracion')"> 2 </a-select-option>
											<a-select-option value="15"> 3 </a-select-option>
											<a-select-option value="16"> 4 </a-select-option>
											<a-select-option value="17"> 5 </a-select-option>
										</a-select>
										<a-select style="width: 50%" v-model="appointmentDate.minutes">
											<a-select-option value="00"> 00 </a-select-option>
											<a-select-option value="15" v-if="!isServiceVisible('valoracion')"> 15 </a-select-option>
											<a-select-option value="30" v-if="!isServiceVisible('valoracion')"> 30 </a-select-option>
											<a-select-option value="45" v-if="!isServiceVisible('valoracion')"> 45 </a-select-option>
										</a-select>
									</div>
								</div>
								<div class="row pt10">
									<div class="col-md-6">
										<label><b>== Origen de contacto ==</b></label>
										<br />
										<a-select v-model="appointmentOrigin" style="width: 100%">
											<a-select-option value="trengo"> Trengo </a-select-option>
											<a-select-option value="phone"> Teléfono </a-select-option>
											<a-select-option value="in_situ"> En sitio </a-select-option>
										</a-select>
									</div>
									<div class="col-md-6">
										<label><b>== Cotización final ==</b></label>
										<br />
										<a-input autocomplete="off" prefix="$" :value="numeral(enableWizardModal ? actualQuotation.quotationData.total : quotationAmount).format('0,0.00')" disabled />
									</div>
								</div>
								<div v-if="internalComments != '' && internalComments != null">
									<hr class="hrDotted" />
									<label><b>== Comentarios Internos ==</b></label>
									<br />
									<a-textarea v-model="internalComments" rows="4" />
								</div>
							</div>
							<div class="col-md-6">
								<div v-if="isServiceVisible('diagnostico')">
									<label>Especifica servicio Diagnóstico / Revisión</label>
									<a-input v-model="diagnosticoServicio" />
								</div>
								<div :class="isServiceVisible('diagnostico') ? 'pt10' : ''" v-if="isServiceVisible('especifico')">
									<label style="padding-right: 5px">Menciona servicios específicos</label>
									<i class="fa fa-plus-circle pointer" style="color: green" @click="onAddEspecifico"></i>
									<div class="mt10">
										<div class="row plr15" :class="index > 0 ? 'pt5' : ''" v-for="(element, index) in especificos" :key="index">
											<div class="col-md-8 p0">
												<a-input v-model="element.value">
													<a-icon slot="addonBefore" type="delete" class="pointer" @click="onDeleteEspecifico(index)" />
												</a-input>
											</div>
											<div class="col-md-4 p0">
												<a-select style="width: 50%" placeholder="horas" v-model="element.hours" @change="calculateSpecificAmount(index)">
													<a-select-option value="0"> 0 hrs.</a-select-option>
													<a-select-option v-for="i in 50" :value="i" :key="i"> {{ i }} hr. </a-select-option>
												</a-select>
												<a-select style="width: 50%" placeholder="mins." v-model="element.minutes" @change="calculateSpecificAmount(index)">
													<a-select-option value="0"> 0 mins. </a-select-option>
													<a-select-option value="15"> 15 mins. </a-select-option>
													<a-select-option value="30"> 30 mins. </a-select-option>
													<a-select-option value="45"> 45 mins. </a-select-option>
												</a-select>
											</div>
											<small>{{ numeral(element.amount).format('$0,0.00') }}</small>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</a-spin>
			</div>
		</a-modal>
	</div>
</template>

<script>
//
import Vue from 'vue'
import { mapGetters } from 'vuex'
import clientsQuickSearchComponent from '@/components/clients/quickSearch'
import clientsQuickSearchMotorcyclesComponent from '@/components/clients/quickSearch/motorcycles'
import clientsDetailGeneralComponent from '@/components/clients/detail/partials/general'
import clientsDetailMotorcyclesComponent from '@/components/clients/detail/partials/motorcycles'
import _ from 'lodash'
import Swal from 'sweetalert2'
import moment from 'moment'
import utilities from '@/services/utilities'
import copy from 'copy-to-clipboard'
import { VMoney } from 'v-money'
import numeral from 'numeral'

let dias = {
	monday: 'Lunes',
	tuesday: 'Martes',
	wednesday: 'Miércoles',
	thursday: 'Jueves',
	friday: 'Viernes',
	saturday: 'Sábado',
	sunday: 'Domingo',
}
let meses = {
	'01': 'Enero',
	'02': 'Febrero',
	'03': 'Marzo',
	'04': 'Abril',
	'05': 'Mayo',
	'06': 'Junio',
	'07': 'Julio',
	'08': 'Agosto',
	'09': 'Septiembre',
	10: 'Octubre',
	11: 'Noviembre',
	12: 'Diciembre',
}

const serviceTime = {
	hours: '0',
	minutes: '0',
}

const precioFactor = numeral(process.env.VUE_APP_FACTOR).value()

export default {
	name: 'QuotesView',
	directives: {
		money: VMoney,
	},
	components: {
		clientsQuickSearchComponent,
		clientsDetailGeneralComponent,
		clientsQuickSearchMotorcyclesComponent,
		clientsDetailMotorcyclesComponent,
	},
	computed: {
		...mapGetters('serviceOrders', ['spinnerLoader', 'spinnerLoaderLabel', 'serviceOrdersList', 'enableWizardModal', 'quotationID']),
		...mapGetters('quotations', ['actualQuotation']),
		...mapGetters('user', ['user']),
		totalSpecific() {
			let total = 0
			this.especificos.forEach((e) => {
				total += numeral(e.amount).value()
			})
			return total
		},
		quotationAmount() {
			return (
				numeral(this.quotationAmounts.mayorMenor).value() + numeral(this.quotationAmounts.valoracion).value() + numeral(this.quotationAmounts.diagnostico).value() + numeral(this.quotationAmounts.gps).value() + numeral(this.quotationAmounts.exploradoras).value() + numeral(this.totalSpecific).value()
			)
		},
		allowedCancelAppointment() {
			//
			// kevin - 31
			// pozos - 11
			// ismael - 1
			// armando - 8
			let allowedUserPermition = [1, 8, 11, 31]
			return !!allowedUserPermition.includes(this.user.user_id)
		},
	},
	data() {
		return {
			dias,
			meses,
			currentTab: 0,
			modal: {
				client: {
					visible: false,
				},
				addClient: {
					visible: false,
				},
				wizard: {
					visible: false,
				},
				addMotorcycle: {
					visible: false,
				},
				appointmentReasons: {
					visible: false,
				},
			},
			payloadData: {
				clientData: {},
				motorcycleData: {},
				serviceData: {},
			},
			radioValue: '',
			radioStyle: {
				display: 'block',
				height: '30px',
				lineHeight: '30px',
			},
			enableDiagnostico: false,
			enableEspecifico: false,
			servicesSelected: [],
			especificos: [],
			diagnosticoServicio: '',
			appreciationAppointmentDate: '',
			internalComments: '',
			columns: [
				{
					title: 'Pre-cita',
					dataIndex: 'id',
					key: 'id',
					align: 'center',
					width: '10%',
				},
				{
					title: 'Cliente / Celular',
					scopedSlots: { customRender: 'client_data' },
					align: 'center',
					width: '20%',
				},
				{
					title: 'Motocicleta',
					scopedSlots: { customRender: 'motorcycle_data' },
					align: 'center',
				},
				{
					title: 'Cotización',
					scopedSlots: { customRender: 'quotation_amount' },
					align: 'center',
					width: '10%',
				},
				{
					title: 'Fecha y hora de cita',
					scopedSlots: { customRender: 'appointment_date' },
					align: 'center',
					width: '10%',
				},
				{
					title: 'Control',
					scopedSlots: { customRender: 'appointment_reasons' },
					align: 'center',
					width: '15%',
				},
			],
			weekDays: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
			weekCounter: 0,
			appointmentRecord: {},
			serviceOrdersListByDay: [],
			selectedAppointmentDate: '',
			appointmentDate: {
				date: null,
				hours: '',
				minutes: '',
			},
			money: {
				decimal: '.',
				thousands: ',',
				precision: 2,
			},
			appointmentOrigin: '',
			quotationAmounts: {
				mayorMenor: 0,
				valoracion: 0,
				diagnostico: 0,
				especifico: 0,
				exploradoras: 0,
				gps: 0,
			},
			searchText: '',
		}
	},
	mounted() {
		this.initModule()
	},
	destroyed() {
		this.$store.commit('clients/SET_STATE', {
			clientsList: [],
		})
		this.payloadData = {
			clientData: {},
			motorcycleData: {},
			serviceData: {},
		}
	},
	methods: {
		moment,
		numeral,
		initModule() {
			this.$store.dispatch('serviceOrders/GET_PRE_SERVICE_ORDER')
		},
		disabledDate(current) {
			// Can not select days before today and today
			return current && current < moment().startOf('day')
		},
		onCreateClient() {
			document.getElementById('clientDetailGeneralComponent_submit').click()
		},
		onCreateMotorcycle() {
			document.getElementById('clientDetailMotorcycleComponent_submit').click()
		},
		onAddClient() {
			this.onShowModal('addClient')
		},
		onAddMotorcycle() {
			this.onShowModal('addMotorcycle')
		},
		async onSelectedClient(payload) {
			if (utilities.objectValidate(payload, 'id', false)) {
				if (this.currentTab == 0) {
					this.payloadData.clientData = _.cloneDeep(payload)
					//
					if (this.modal.addClient.visible) {
						this.onCloseModal('addClient')
					}
					await this.$store.dispatch('clients/SEARCH_MOTORCYCLES', payload.id)
				}
				if (this.currentTab == 1) {
					this.payloadData.motorcycleData = _.cloneDeep(payload)
				}

				if (this.currentTab == 0 || this.currentTab == 1) {
					this.currentTab++
				}
			}
		},
		addClient() {
			if (this.currentTab == 0) {
				this.$refs.clientsQuickSearchComponent.onSelectedRecord()
				return false
			}

			if (this.currentTab == 1) {
				this.$refs.clientsQuickSearchMotorcyclesComponent.onSelectedRecord()
				return false
			}

			if (this.currentTab == 2) {
				// Validación de servicios seleccionados
				if (!this.servicesSelected.length) {
					Swal.fire({
						title: 'Atención',
						text: 'Debes seleccionar al menos un servicio para la cita',
						icon: 'warning',
						confirmButtonText: 'Ok',
					})
					return false
				}

				// Validación de diagnóstico
				if (this.isServiceVisible('diagnostico')) {
					if (this.diagnosticoServicio == '') {
						Swal.fire({
							title: 'Atención',
							text: 'Debes ingresar descripción del Diagnóstico / Revisión',
							icon: 'warning',
							confirmButtonText: 'Ok',
						})
						return false
					}
				}

				// Validación de servicio menor, mayor o motor
				if ((this.isServiceVisible('menor') || this.isServiceVisible('mayor') || this.isServiceVisible('motor')) && !numeral(this.quotationAmounts.mayorMenor).value()) {
					Swal.fire({
						title: 'Atención',
						text: 'Debes ingresar monto del servicio',
						icon: 'warning',
						confirmButtonText: 'Ok',
					})
					return false
				}

				// Validación de contenido de específicos
				if (this.isServiceVisible('especifico') && !this.allowAddEspecifico()) {
					return false
				}

				// Validación de la fecha de la cita
				if (
					!utilities.objectValidate(this.appointmentDate, 'date', false) ||
					!utilities.objectValidate(this.appointmentDate, 'hours', false) ||
					!utilities.objectValidate(this.appointmentDate, 'minutes', false)
				) {
					Swal.fire({
						title: 'Atención',
						text: 'Debes ingresar fecha y hora de la cita',
						icon: 'warning',
						confirmButtonText: 'Ok',
					})
					return false
				}

				// Se valida origen de la cita
				if (this.appointmentOrigin == '') {
					Swal.fire({
						title: 'Atención',
						text: 'Debes seleccionar el origen de contacto para la cita',
						icon: 'warning',
						confirmButtonText: 'Ok',
					})
					return false
				}

				// Validamos descripciones y precios de cada uno de los específicos
				if (!this.allowAddEspecifico()) {
					return false
				}

				this.payloadData = {
					...this.payloadData,
					serviceData: {
						services: _.cloneDeep(this.servicesSelected.map((e) => e)),
						especificos: _.cloneDeep(this.especificos),
						diagnostico: _.clone(this.diagnosticoServicio),
						quotationAmounts: this.quotationAmounts,
					},
					quotation_amount: numeral(this.quotationAmount).value(),
					appointment_origin: this.appointmentOrigin,
					from: 'panel',
					appointment_date: `${moment(this.appointmentDate.date).format('YYYY-MM-DD')} ${this.appointmentDate.hours}:${this.appointmentDate.minutes}`,
				}

				if (utilities.objectValidate(this.payloadData, 'id', false)) {
					this.$store.dispatch('serviceOrders/UPDATE_PRE_SERVICE_ORDER', this.payloadData).then(() => {
						copy(this.getMessageData())
						this.onCloseModal('wizard')
					})
				} else {
					//
					if (this.enableWizardModal) {
						this.payloadData.quotationID = this.quotationID
					}

					this.$store.dispatch('serviceOrders/CREATE_PRE_SERVICE_ORDER', this.payloadData).then(() => {
						copy(this.getMessageData())
						this.onCloseModal('wizard')
					})
				}
			}
		},
		getMessageData() {
			let appointmentServices = ''
			this.payloadData.serviceData.services.forEach((e) => {
				//
				if (e == 'especifico') {
					this.payloadData.serviceData.especificos.forEach((j) => {
						//
						if (j instanceof Object) {
							appointmentServices += `- Servicio Específico: ${j.value}\n`
						} else {
							appointmentServices += `- Servicio Específico: ${j}\n`
						}
					})
				}
				if (e == 'diagnostico') {
					appointmentServices += `- Servicio Diagnóstico: ${this.payloadData.serviceData.diagnostico}\n`
				}
				if (e == 'valoracion') {
					appointmentServices += `- Valoración Técnica\n`
				}
				if (e == 'mayor' || e == 'menor') {
					appointmentServices += `- Servicio ${e}\n`
				}
			})

			let copyMessage = `✅ Servicio a realizar: \n${appointmentServices}`
			// buscar en cotizaciones
			copyMessage += `✅ Nombre: ${this.payloadData.clientData.client_name.toUpperCase()} ${this.payloadData.clientData.client_surname.toUpperCase()}\n`
			copyMessage += `✅ Teléfono: ${this.payloadData.clientData.primary_phone}\n`
			copyMessage += `✅ Moto: ${this.payloadData.motorcycleData.nombre_marca || this.payloadData.motorcycleData.marca_otro} ${this.payloadData.motorcycleData.nombre_modelo || this.payloadData.motorcycleData.modelo_otro
				}\n`
			// validación de placa
			if (utilities.objectValidate(this.payloadData, 'motorcycleData.placa', false)) {
				copyMessage += `✅ Placa: ${this.payloadData.motorcycleData.placa}\n`
			}
			copyMessage += `✅ Fecha: ${dias[moment(this.appointmentDate.date).format('dddd').toLowerCase()]} ${moment(this.appointmentDate.date).format('DD')} de ${meses[moment(this.appointmentDate.date).format('MM')]
				} de ${moment(this.appointmentDate.date).format('YYYY')} a las ${this.appointmentDate.hours}:${this.appointmentDate.minutes} hrs. \n`

			if (utilities.objectValidate(this.payloadData, 'quotation_amount', false)) {
				copyMessage += `✅ Tu cotización es por ${numeral(this.payloadData.quotation_amount).format('$0,0.00')}`
			}

			Vue.prototype.$notification.success({
				message: 'Citas',
				description: 'Texto de cotización en portapapeles',
			})

			return copyMessage
		},
		onCloseModal(section) {
			this.modal[section].visible = false

			if (section == 'appointmentReasons') {
				this.appointmentRecord = {}
				copy(' ')
			}

			if (section == 'wizard') {
				this.payloadData = {
					clientData: {},
					motorcycleData: {},
					serviceData: {},
				}
				this.currentTab = 0
				this.enableDiagnostico = false
				this.enableEspecifico = false
				this.servicesSelected = []
				this.especificos = []
				this.diagnosticoServicio = ''
				this.appointmentDate = {
					date: null,
					hours: '',
					minutes: '',
				}
				this.appreciationAppointmentDate = ''
				this.internalComments = ''
				this.radioValue = ''
				this.appointmentOrigin = ''
				this.quotationAmounts = {
					mayorMenor: 0,
					valoracion: 0,
					diagnostico: 0,
					especifico: 0,
				}
				//
				this.$store.commit('serviceOrders/SET_STATE', {
					enableWizardModal: false,
					quotationID: '',
				})
			}
		},
		onShowModal(section) {
			this.modal[section].visible = true
		},
		returnStep() {
			this.currentTab--
		},
		onChangeService({ target }) {
			// console.log('target-->', target)
			let { value, type } = target
			let servicesSelectedIndex = this.servicesSelected.findIndex((e) => e == value)

			if (type == 'radio') {
				switch (value) {
					case 'mayor':
						servicesSelectedIndex = this.servicesSelected.findIndex((e) => e == 'menor')
						break
					case 'menor':
						servicesSelectedIndex = this.servicesSelected.findIndex((e) => e == 'mayor')
						break
				}
				this.servicesSelected.splice(servicesSelectedIndex, 1)
				this.servicesSelected.push(value)
				this.quotationAmounts.mayorMenor = numeral(0).format('0,0.00')
			} else {
				//
				if (value == 'valoracion') {
					this.quotationAmounts.valoracion = '100.00'
					this.appointmentDate.hours = ''
					this.appointmentDate.minutes = '00'
				}
				if (servicesSelectedIndex == -1) {
					this.servicesSelected.push(value)
				} else {
					this.servicesSelected.splice(servicesSelectedIndex, 1)
					this.quotationAmounts[value] = numeral(0).format('0,0.00')
				}
			}

			if (this.isServiceVisible('especifico') && !this.especificos.length) {
				this.onAddEspecifico()
			}
			if (!this.isServiceVisible('especifico') && this.especificos.length) {
				this.especificos = []
			}
		},
		allowAddEspecifico() {
			let errors = []
			for (let index = 0; index < this.especificos.length; index++) {
				const element = this.especificos[index]

				if (element.value == '') {
					errors.push(`- Debes ingresar descripción del específico #${index + 1}`)
				}
				if (element.hours == '0' && element.minutes == '0') {
					errors.push(`- Debes ingresar tiempo de mano de obra del específico #${index + 1}`)
				}
			}

			if (errors.length) {
				Swal.fire({
					title: 'Atención',
					html: errors.join('<br>'),
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
				return false
			}
			return true
		},
		onAddEspecifico() {
			if (this.allowAddEspecifico()) {
				this.especificos.push({
					...serviceTime,
					amount: 0,
					value: '',
				})
			}
		},
		onDeleteEspecifico(index) {
			this.especificos.splice(index, 1)
		},
		getDate(weekDay, format) {
			let appointmentDate = moment().startOf('week').add(weekDay, 'd')
			if (this.weekCounter != 0) {
				if (this.weekCounter > 0) {
					appointmentDate = appointmentDate.add(this.weekCounter, 'week')
				} else {
					appointmentDate = appointmentDate.subtract(Math.abs(this.weekCounter), 'week')
				}
			}
			return appointmentDate.format(format)
		},
		getAppointments(weekDay) {
			let appointmentDate = moment().startOf('week').add(weekDay, 'd')
			if (this.weekCounter != 0) {
				if (this.weekCounter > 0) {
					appointmentDate = moment(appointmentDate).add(this.weekCounter, 'week')
				} else {
					appointmentDate = moment(appointmentDate).subtract(this.weekCounter, 'week')
				}
			}
			return this.serviceOrdersList.filter((e) => e.appointment_date == appointmentDate.format('YYYY-MM-DD')).length
		},
		getServices(type, appointmentDate) {
			//
			return this.serviceOrdersList.filter((e) => e.service_data.services.includes(type) && moment(e.appointment_date).format('YYYY-MM-DD') == appointmentDate).length
		},
		onViewAppointment(id) {
			this.appointmentRecord = _.cloneDeep(this.serviceOrdersList.find((e) => e.id == id))
			this.onShowModal('appointmentReasons')
		},
		onCopyAppointment(id) {
			let record = _.cloneDeep(this.serviceOrdersList.find((e) => e.id == id))
			// console.log('record-->', record)
			this.payloadData = {
				...record,
				clientData: _.cloneDeep(record.client_data),
				motorcycleData: _.cloneDeep(record.motorcycle_data),
				serviceData: _.cloneDeep(record.service_data),
			}
			delete this.payloadData.client_data
			delete this.payloadData.motorcycle_data
			delete this.payloadData.service_data
			// console.log(this.payloadData)
			copy(this.getMessageData())
		},
		onConfirmAppointment(id) {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas confirmar la asistencia del cliente a su cita?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('serviceOrders/CONFIRM_PRE_SERVICE_ORDER', id)
				}
			})
		},
		onIngresedAppointment(id) {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas confirmar la recepción de esta cita?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('serviceOrders/INGRESED_PRE_SERVICE_ORDER', id)
				}
			})
		},
		onCancelAppointment(id) {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas cancelar esta cita?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('serviceOrders/CANCEL_PRE_SERVICE_ORDER', id)
				}
			})
		},
		onEditAppointment(id) {
			let record = _.cloneDeep(this.serviceOrdersList.find((e) => e.id == id))
			// console.log('onEditAppointment-->', id)
			// console.log('record-->', record)

			this.payloadData = {
				...record,
				clientData: _.cloneDeep(record.client_data),
				motorcycleData: _.cloneDeep(record.motorcycle_data),
				serviceData: _.cloneDeep(record.service_data),
			}
			delete this.payloadData.client_data
			delete this.payloadData.motorcycle_data
			delete this.payloadData.service_data

			//
			let schedule = record.appreciationAppointmentDate.split(':')
			this.appointmentDate = {
				date: moment(record.appointment_date).format('YYYY-MM-DD'),
				hours: schedule[0],
				minutes: schedule[1],
			}
			this.appointmentOrigin = _.clone(record.appointment_origin)
			this.internalComments = utilities.objectValidate(record, 'internal_comments', '')
			this.diagnosticoServicio = record.service_data.diagnostico

			if (utilities.objectValidate(record, 'service_data.quotationAmounts', false)) {
				this.quotationAmounts = _.cloneDeep(record.service_data.quotationAmounts)
			}

			// Evalua si cuenta con específicos
			this.servicesSelected = _.cloneDeep(record.service_data.services)

			record.service_data.services.forEach((e) => {
				if (e == 'mayor' || e == 'menor') {
					this.radioValue = e
				} else {
					if (e == 'especifico') {
						record.service_data.especificos.forEach((element) => {
							if (element instanceof Object) {
								this.especificos.push(element)
							} else {
								this.especificos.push({
									value: element,
								})
							}
						})
					}
				}
			})

			// establece horario si hubo una Valoración Técnica
			this.appreciationAppointmentDate = utilities.objectValidate(record, 'appreciationAppointmentDate', '')

			this.currentTab = 2
			this.onShowModal('wizard')
		},
		filterAppointments(appointmentDate) {
			// console.log('filterAppointments::appointmentDate-->', appointmentDate)
			this.searchText = ''
			this.serviceOrdersListByDay = []
			this.selectedAppointmentDate = _.clone(appointmentDate)
			this.serviceOrdersListByDay = _.cloneDeep(this.serviceOrdersList.filter((e) => e.appointment_date == appointmentDate))
		},
		getStatusAppointments(weekDay, type) {
			let appointmentDate = moment().startOf('week').add(weekDay, 'd')
			if (this.weekCounter != 0) {
				if (this.weekCounter > 0) {
					appointmentDate = moment(appointmentDate).add(this.weekCounter, 'week')
				} else {
					appointmentDate = moment(appointmentDate).subtract(this.weekCounter, 'week')
				}
			}
			switch (type) {
				case 'confirmed':
					return this.serviceOrdersList.filter((e) => e.appointment_date == appointmentDate.format('YYYY-MM-DD') && e.confirmed && !e.ingresed).length
					break
				case 'ingresed':
					return this.serviceOrdersList.filter((e) => e.appointment_date == appointmentDate.format('YYYY-MM-DD') && e.confirmed && e.ingresed).length
					break
				case 'canceled':
					return this.serviceOrdersList.filter((e) => e.appointment_date == appointmentDate.format('YYYY-MM-DD') && !e.active).length
					break
				default:
					return this.serviceOrdersList.filter((e) => e.appointment_date == appointmentDate.format('YYYY-MM-DD') && !e.confirmed && !e.ingresed).length
					break
			}
		},
		onResetService() {
			this.radioValue = ''
			let indexMenor = this.servicesSelected.findIndex((e) => e == 'menor')
			let indexMayor = this.servicesSelected.findIndex((e) => e == 'mayor')
			let indexMotor = this.servicesSelected.findIndex((e) => e == 'motor')

			this.quotationAmounts.mayorMenor = 0

			if (indexMenor != -1) {
				this.servicesSelected.splice(indexMenor, 1)
			}
			if (indexMayor != -1) {
				this.servicesSelected.splice(indexMayor, 1)
			}
			if (indexMotor != -1) {
				this.servicesSelected.splice(indexMotor, 1)
			}
		},
		isServiceVisible(service) {
			let response = this.servicesSelected.findIndex((e) => e == service) >= 0 ? true : false
			if (service == 'especifico' && response && this.especificos.length == 0) {
				this.onAddEspecifico()
			}
			return response
		},
		calculateSpecificAmount(index) {
			let hours = numeral(this.especificos[index].hours).value()
			let minutes = numeral(this.especificos[index].minutes).value()

			this.especificos[index].amount = numeral(precioFactor * hours + (precioFactor / 60) * minutes).value()
		},
		onSearch() {
			this.serviceOrdersListByDay = []
			this.$store
				.dispatch('serviceOrders/SEARCH_PRE_SERVICE_ORDER', {
					searchText: this.searchText,
				})
				.then((response) => {
					this.serviceOrdersListByDay = _.cloneDeep(response)
				})
		},
		downloadQuotation(id) {
			this.$store.dispatch('quotations/GET_QUOTATION', { id })
		},
	},
	watch: {
		serviceOrdersList: {
			deep: true,
			handler() {
				this.filterAppointments(this.selectedAppointmentDate)
			},
		},
		enableWizardModal(isVisible) {
			if (isVisible) {
				this.onShowModal('wizard')
				//
				let { service_tag } = this.actualQuotation.quotationData
				let { unit_price } = this.actualQuotation.quotationElements.find((e) => e.sku == 'SER-MEC') || { unit_price: 0 }
				let spendingTime = 0

				this.onChangeService({
					target: {
						value: service_tag,
						type: service_tag == 'mayor' || service_tag == 'menor' ? 'radio' : 'checkbox',
					},
				})

				if (service_tag == 'mayor' || service_tag == 'menor') {
					this.radioValue = service_tag
					this.quotationAmounts.mayorMenor = numeral(this.actualQuotation.quotationData.total).format('0,0.00')
				}

				if (service_tag == 'especifico') {
					// establecemos el valor de la cotización del específico y el valor de mano de obra en horas
					let hours = 0
					let minutes = 0

					if (unit_price) {
						spendingTime = (unit_price / precioFactor).toString().split('.')
						hours = spendingTime[0].toString()
						minutes = ((spendingTime[1] / 10) * 60).toString()
					}

					this.especificos[0] = {
						...this.especificos[0],
						value: this.actualQuotation.quotationData.service_name,
						hours,
						minutes,
					}
					this.calculateSpecificAmount(0)
				}
			}
		},

	},
}
</script>

<style lang="scss">
[data-kit-theme='default'] .ant-checkbox-group-item {
	display: block !important;
	margin-right: 8px;
}

.dot {
	height: 20px;
	width: 20px;
	border-radius: 50%;
	display: inline-block;
	margin-right: 5px;
	border: solid 1px rgb(31, 31, 34);
}

.mayor {
	background-color: #0f5132;
}

.menor {
	background-color: #faad15;
}

.diagnostico {
	background-color: #b6d4fe;
}

.valoracion {
	background-color: #d3d6d8;
}

.especifico {
	background-color: #f5c2c7;
}

.motor {
	background-color: #cff4fc;
}

.appointmentConfirmed {
	background-color: #ffecb5;
}

.appointmentIngresed {
	background-color: #badbcc;
}

.appointmentCanceled {
	background-color: #f5c2c7;
}
</style>