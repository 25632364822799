<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="small" :spinning="spinnerLoader">
			<div class="row">
				<div class="col-md-12 pt10" v-if="motorcyclesList.length > 0">
					<a-table :columns="columns" :dataSource="motorcyclesList" :row-selection="rowSelection" rowKey="key" :pagination="false">
						<div slot="marca" slot-scope="record">
							{{ record.nombre_marca | replaceIfLogicalFalse(record.marca_otro) | replaceIfLogicalFalse('-No establecido-') }}
						</div>
						<div slot="modelo" slot-scope="record">
							{{ record.nombre_modelo | replaceIfLogicalFalse(record.modelo_otro) | replaceIfLogicalFalse('-No establecido-') }}
						</div>
					</a-table>
				</div>
				<div class="col-md-12 text-center" v-else>El cliente no tiene motocicletas asociadas</div>
				<div class="col-md-12 text-center">
					<hr class="hrDotted" />
				</div>
				<div class="col-md-4 offset-md-4 text-center">
					<a-button class="btn btn-info btn-block mt10" icon="plus" @click="onAddRecord">Añadir motocicleta</a-button>
				</div>
			</div>
		</a-spin>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'

export default {
	name: 'clientsQuickSearchMotorcyclesComponent',
	computed: {
		...mapGetters('clients', ['motorcyclesList', 'spinnerLoader', 'spinnerLoaderLabel']),
	},
	data() {
		return {
			selectedRecord: {},
			columns: [
				{
					title: 'ID',
					dataIndex: 'id',
					key: 'id',
					align: 'center',
				},
				{
					title: 'Marca',
					scopedSlots: { customRender: 'marca' },
					align: 'center',
				},
				{
					title: 'Modelo',
					scopedSlots: { customRender: 'modelo' },
					align: 'center',
				},
				{
					title: 'Año',
					dataIndex: 'anio',
					key: 'anio',
					align: 'center',
				},
				{
					title: 'Placa',
					dataIndex: 'placa',
					key: 'placa',
					align: 'center',
				},
			],
			rowSelection: {
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRecord = _.cloneDeep(selectedRows[0])
				},
				type: 'radio',
			},
		}
	},
	methods: {
		onSelectedRecord() {
			if (utilities.objectValidate(this.selectedRecord, 'id', false)) {
				this.$emit('selectedRecord', this.selectedRecord)
			} else {
				Swal.fire({
					title: 'Catálogo de Clientes',
					text: 'Debes seleccionar un registro para continuar',
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
			}
		},
		onAddRecord() {
			this.$emit('addMotorcycle')
		},
	},
	watch: {
		visible() {
			this.$store.commit('clients/SET_STATE', {
				motorcyclesList: [],
			})
		},
	},
}
</script>